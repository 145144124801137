import React from 'react';
import { graphql } from 'gatsby';

import munger from './landing-page.munger';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import HomeTemplate from '@components/templates/HomeTemplate/HomeTemplate';
import LandingPageTemplate from '@components/templates/LandingPageTemplate/LandingPageTemplate';
import GTMPageLoad from '@components/atoms/GTM/GTMPageLoad';

const LandingPage = ({ data, location }) => {
  const landingPageJson = munger(data);

  const landingPageVariant = () => {
    if (landingPageJson.path.alias === null) {
      return <HomeTemplate data={landingPageJson} />;
    }

    return <LandingPageTemplate data={landingPageJson} />;
  };

  return (
    <Layout location={location}>
      <SEO title={landingPageJson.title} />
      <GTMPageLoad
        {...{
          page_type: location.pathname === `/` ? `homepage` : `content`,
          page_taxonomy_field:
            location.pathname === `/` ? `Homepage` : `Content page`,
          page_name: landingPageJson.title
        }}
      />
      {landingPageVariant()}
    </Layout>
  );
};

export const query = graphql`
  query LandingPage($PageId: String!) {
    nodeLandingPageJson(drupal_id: { eq: $PageId }) {
      title
      path {
        alias
      }
      relationships {
        field_landing_page_hero {
          field_hero_block_type_2_image_2 {
            alt
            height
            title
            width
          }
          field_hero_block_type_two_body_c
          field_hero_block_type_two_title
          field_hero_block_type_two_image_ {
            height
            title
            width
            alt
          }
          field_hero_block_type_2_bkgrnd_i {
            alt
            height
            title
            width
          }
          relationships {
            field_hero_block_type_2_bkgrnd_i {
              uri {
                url {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            field_hero_block_type_2_image_2 {
              uri {
                url {
                  publicURL
                }
              }
            }
            field_hero_block_type_two_image_ {
              uri {
                url {
                  publicURL
                }
              }
            }
          }
        }
        field_content_block {
          _xtypename
          field_content_block_title
          field_content_block_text
          field_content_block_image_side
          field_content_block_cta {
            url
            title
          }
          relationships {
            field_image_with_options {
              relationships {
                field_image {
                  relationships {
                    image {
                      uri {
                        url {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            field_use_case_item_file {
              fileurl
              filename
            }
            field_use_case_item_image {
              uri {
                url {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              image_style_uri {
                crop_1_1 {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          field_use_case_item_title
          field_use_case_item_body
          field_use_case_category
          field_use_case_item_image {
            alt
            height
            title
            width
          }
        }
      }
    }
    allMenuLinkContentMenuLinkContentJson(
      filter: { menu_name: { eq: "find-out-more" } }
    ) {
      nodes {
        menu_name
        id
        title
        description
        link {
          title
          url
          options {
            attributes {
              icon
            }
          }
        }
      }
    }
    allNodeArticleJson(sort: { fields: created, order: DESC }, limit: 10) {
      nodes {
        drupal_id
        title
        field_article_description
        path {
          alias
        }
        created
        field_article_featured
        field_article_read_time
        field_article_authored_by
        relationships {
          field_article_image {
            image {
              alt
            }
            relationships {
              image {
                uri {
                  url {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default LandingPage;
