import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import { getDrupalURL } from '@helpers';

import './ImageTextHero.scss';

const ImageTextHero = ({
  title,
  body,
  imageOne,
  imageTwo,
  backgroundImage
}) => {
  return (
    <>
      <header className="mars-collections-hero">
        {backgroundImage?.url && (
          <div className="hero-background">
            {/* <img
              className="hero-background__image"
              src={getDrupalURL() + backgroundImage.url}
            /> */}
            <GatsbyImage
              className="hero-background__image"
              image={getImage(backgroundImage.url)}
            />
          </div>
        )}
        <div className="mars-container mars-grid mars-grid--2-cols">
          <div className="mars-collections-hero__content mars-padding-y--sm">
            <h1 className="mars-alpha mars-margin-bottom--xs mars-text-colour--brand1">
              {title}
            </h1>
            <p className="mars-collections-hero__description mars-body-large mars-margin-bottom--md">
              {body}
            </p>
          </div>
          <div className="mars-collections-hero__image-container">
            <MarsIcon
              icon="dots"
              width="220px"
              height="175px"
              className="mars-collections-hero__shape mars-icon--fill mars-icon--brand4"
            />
            {imageOne && (
              <img
                className="mars-collections-hero__image"
                title={imageOne.title}
                src={`${imageOne.url}`}
                alt={imageOne.alt}
              />
              // <GatsbyImage
              //   className="mars-collections-hero__image"
              //   title={imageOne.title}
              //   image={getImage(imageOne.url)}
              //   alt={imageOne.alt}
              // />
            )}
            {imageTwo && (
              <img
                className="mars-collections-hero__image"
                title={imageTwo.title}
                src={`${imageTwo.url}`}
                alt={imageTwo.alt}
              />
              // <GatsbyImage
              //   className="mars-collections-hero__image"
              //   title={imageTwo.title}
              //   image={getImage(imageTwo.url)}
              //   alt={imageTwo.alt}
              // />
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default ImageTextHero;

ImageTextHero.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
};
