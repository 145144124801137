import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import { getDrupalURL } from '@helpers';

import './ContentBlock.scss';

const ContentBlock = ({
  image,
  image2,
  alignment = `image-left`,
  children
}) => {
  return (
    // Use classnames to conditionally add className strings.
    <section
      className={classnames(
        `mars-content-block`,
        `mars-content-block--${alignment}`,
        { 'mars-content-block--two-images': image2 }, // Only add modifier if image2 is truthy.
        `mars-container`,
        `mars-grid mars-grid--2-cols`
      )}>
      <div className="mars-content-block__image-container">
        <MarsIcon
          icon="dots"
          width="220px"
          height="175px"
          className="mars-content-block__shape mars-icon--fill mars-icon--brand2"
        />
        {image && (
          // <img
          //   className="mars-content-block__image"
          //   src={`${getDrupalURL()}${image.path}`}
          //   alt={image.alt}
          // />
          <>
            {/* <StaticImage
            className="mars-content-block__image"
            src={`${image.path}`}
            alt={image.alt}
          /> */}
            <GatsbyImage
              className="mars-content-block__image"
              image={getImage(image.path)}
              alt={image.alt}
            />
          </>
        )}
        {image2 && (
          // <img
          //   className="mars-content-block__image"
          //   src={`${getDrupalURL()}${image.path}`}
          //   alt={image2.alt}
          // />
          <>
            {/* <StaticImage
            className="mars-content-block__image"
            src={`${image.path}`}
            alt={image2.alt}
          /> */}
            <GatsbyImage
              className="mars-content-block__image"
              image={getImage(image.path)}
              alt={image2.alt}
            />
          </>
        )}
      </div>
      <div className="mars-content-block__content">{children}</div>
    </section>
  );
};

ContentBlock.propTypes = {
  image: PropTypes.shape({
    path: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,
  image2: PropTypes.shape({
    path: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),
  alignment: PropTypes.string,
  children: PropTypes.node
};

export default ContentBlock;
