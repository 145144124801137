export default ({
  nodeLandingPageJson,
  allMenuLinkContentMenuLinkContentJson,
  allNodeArticleJson
}) => {
  const { title, path, relationships } = nodeLandingPageJson;
  const { field_content_block, field_landing_page_hero } = relationships;

  const mapImages = (block) => {
    if (!block.relationships || block._xtypename === `paragraph__hero_block`)
      return [];
    return block.relationships.field_image_with_options.map((image) => ({
      path:
        image.relationships.field_image &&
        image.relationships.field_image.relationships.image.uri.url,
      alt: block.field_content_block_title
    }));
  };

  const mapBlock = (block) => ({
    title: block.field_content_block_title,
    type: block._xtypename,
    description: block.field_content_block_text,
    alignment: `image-${block.field_content_block_image_side}`.toLowerCase(),
    ctas: block.field_content_block_cta,
    images: mapImages(block)
  });

  const mapHeroTypeTwo = (block) => ({
    title: block.field_hero_block_type_two_title,
    body: block.field_hero_block_type_two_body_c,
    backgroundImage: {
      ...block.field_hero_block_type_2_bkgrnd_i,
      url: block.relationships.field_hero_block_type_2_bkgrnd_i.uri.url
    },
    imageOne: {
      ...block.field_hero_block_type_two_image_,
      url: block.relationships.field_hero_block_type_two_image_.uri.url
        .publicURL
    },
    imageTwo: {
      ...block.field_hero_block_type_2_image_2,
      url: block.relationships.field_hero_block_type_2_image_2.uri.url.publicURL
    }
  });

  const mapUseCaseBlock = (block) => ({
    title: block.field_use_case_item_title,
    type: block._xtypename,
    body: block.field_use_case_item_body,
    download: block.relationships.field_use_case_item_file,
    category: block.field_use_case_category,
    images: {
      url:
        block.relationships?.field_use_case_item_image?.image_style_uri.find(
          (item) => typeof item.crop_1_1 !== `undefined`
        ).crop_1_1 ?? null,
      alt: block?.field_use_case_item_image?.alt ?? null,
      height: block?.field_use_case_item_image?.height ?? null,
      title: block?.field_use_case_item_image?.title ?? null,
      width: block?.field_use_case_item_image?.width ?? null
      // name: decodeURI(
      //   block?.relationships?.field_use_case_item_image?.uri?.url
      //     ? block?.relationships?.field_use_case_item_image?.uri?.url
      //         ?.split(`/`)
      //         .pop()
      //     : null
      // )
    }
  });

  const mapLinkCards = (card) => ({
    icon: card.link.options.attributes && card.link.options.attributes.icon,
    title: card.link.title,
    url: card.link.url
  });

  const contentBlocks = () => {
    return field_content_block
      .filter((block) =>
        [`paragraph__text_with_image`, `paragraph__case_study_item`].includes(
          block._xtypename
        )
      )
      .map((item) => {
        switch (item._xtypename) {
          case `paragraph__text_with_image`:
            return mapBlock(item);
          case `paragraph__case_study_item`:
            return mapUseCaseBlock(item);
          default:
            console.error(`Unknown block type: ${item._xtypename} `, item);
        }
      });
  };

  const hero = () => {
    const block =
      field_landing_page_hero ||
      field_content_block.find(
        (block) => block._xtypename === `paragraph__hero_block`
      );
    if (!block) return;
    if (typeof block.field_hero_block_type_two_title !== `undefined`) {
      return mapHeroTypeTwo(block);
    }
    return mapBlock(block);
  };

  const featuredLinkCards = () => {
    if (!allMenuLinkContentMenuLinkContentJson) return [];
    return {
      cards: allMenuLinkContentMenuLinkContentJson.nodes.map(mapLinkCards)
    };
  };

  const latestArticles = () => {
    if (!allNodeArticleJson) return [];
    return allNodeArticleJson.nodes.map((article) => ({
      id: article.drupal_id,
      title: article.title,
      url: article.path.alias,
      description: article.field_article_description,
      imageUrl:
        article.relationships.field_article_image?.relationships.image.uri
          .url || null,
      imageAlt: article.relationships.field_article_image?.image.alt,
      created: Object.freeze(new Date(article.created)),
      readTime: article.field_article_read_time,
      featured: article.field_article_featured
    }));
  };

  return {
    title,
    path,
    hero: hero(),
    contentBlocks: contentBlocks(),
    featuredLinkCards: featuredLinkCards(),
    latestArticles: latestArticles()
  };
};
