import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './HomeHero.scss';

const HomeHero = ({ title, ctas }) => {

  return (
    <div className="mars-home-hero">
      <div className="mars-container">
        <div className="mars-home-hero__copy-container mars-text--center mars-max-width--md mars-padding-x--xxl">
          <h1 className="mars-home-hero__title mars-alpha mars-text-colour--brand1 mars-padding-x--sm">
            {title}
          </h1>
          <div className="mars-home-hero__buttons-container">
            <div className="mars-home-hero__buttons">
              {ctas.map((cta, index) => {
                return (
                  <Link
                    to={cta.url}
                    className="mars-button"
                    key={cta.url + index}>
                    {cta.title}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <span className="mars-home-hero__background mars-home-hero__background-left-leaf" />
      <span className="mars-home-hero__background mars-home-hero__background-right-leaf" />
      <span className="mars-home-hero__background mars-home-hero__background-roads" />
      <span className="mars-home-hero__background mars-home-hero__background-people" />
    </div>
  );
};

export default HomeHero;

HomeHero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired
};
