import React from 'react';
import Link from 'gatsby-link';
import HomeHero from '@components/organisms/HomeHero/HomeHero';
import ArticlesGrid from '@components/organisms/ArticlesGrid/ArticlesGrid';
import ContentBlock from '@components/molecules/ContentBlock/ContentBlock';
import OpenMsFormButton from '@components/molecules/OpenMsFormButton/OpenMsFormButton';

const HomeTemplate = ({ data }) => {
  return (
    <>
      <HomeHero {...data.hero} />
      <div className="mars-container mars-padding-top--xl">
        <h2 className="mars-gamma mars-text--center mars-padding-bottom--lg">
          Latest Articles
        </h2>
        <ArticlesGrid articles={data.latestArticles} />
      </div>
      {
        // Loop through all ContentBlocks data and return ContentBlock for each.
        data.contentBlocks.map((block, index) => {
          return (
            <div
              className="mars-margin-y--xl mars-padding-y--md mars-overflow-hidden"
              key={block.title + index}>
              <ContentBlock
                image={block.images[0]}
                image2={block.images[1]}
                alignment={block.alignment}>
                <h2 className="mars-gamma">{block.title}</h2>
                {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
                <div dangerouslySetInnerHTML={{ __html: block.description }} />
                <div className="mars-content-block__ctas-container">
                  <div className="mars-content-block__ctas">
                    {block.ctas.map((cta, index) => {
                      return cta.url.match(`.pdf`) ? (
                        <a
                          href={cta.url}
                          className="mars-button"
                          key={cta.url + index}>
                          {cta.title}
                        </a>
                      ) : (
                        <Link
                          to={cta.url}
                          className="mars-button"
                          key={cta.url + index}>
                          {cta.title}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </ContentBlock>
            </div>
          );
        })
      }
      <OpenMsFormButton formUrl="https://forms.office.com/Pages/ResponsePage.aspx?id=OskoFRcZoE-1ALqcjkyZPyrIsmwGEu9EpNkZBhIzltBUQTJNSkQ5NDdBME45VlNKNFNNQTAzMjVGTi4u&embed=true" />
    </>
  );
};

export default HomeTemplate;
