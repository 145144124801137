import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getDrupalURL } from '@helpers';

import classnames from 'classnames';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './styles.scss';

export const UseCaseBlock = ({ block: { title, body, images, download } }) => {
  return (
    <section className="mars-use-case-block">
      <div className="mars-use-case-block__content_wrapper">
        <h3 className="mars-delta">{title}</h3>
        <p className="mars-use-case-block__body">{body}</p>

        {download && (
          <a
            className="mars-button mars-button--primary"
            href={`/api/pdf/${download.fileurl}`}
            target="_blank">
            <MarsIcon icon="external" width="16px" height="16px" />
            {`  `}
            {download.filename}
          </a>
        )}
      </div>
      <div className="mars-use-case-block__image">
        {images.url && (
          // <img title={images.title} src={`${images.url}`} alt={images.alt} />
          <GatsbyImage
            title={images.title}
            image={getImage(images.url)}
            alt={images.alt}
          />
        )}
        {!images.url && (
          <span className="mars-use-case-block__image-placeholder"></span>
        )}
      </div>
    </section>
  );
};
