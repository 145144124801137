import React from 'react';
import Link from 'gatsby-link';
import ContentBlock from '@components/molecules/ContentBlock/ContentBlock';
import { UseCaseBlock } from '@components/molecules/UseCaseBlock';
import ImageTextHero from '@components/organisms/ImageTextHero/ImageTextHero';

const contentBlocksPart = ({ block }) => (
  <ContentBlock
    image={block.images[0]}
    image2={block.images[1]}
    alignment={block.alignment}>
    <h2 className="mars-gamma">{block.title}</h2>
    {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
    <div dangerouslySetInnerHTML={{ __html: block.description }} />
    <div className="mars-content-block__ctas">
      {block.ctas.map((cta, index) => {
        return cta.url.match(`@`) ? (
          <a
            href={cta.url}
            className="mars-button mars-margin-right--sm"
            key={cta.url + index}>
            {cta.title}
          </a>
        ) : (
          <Link
            to={cta.url}
            className="mars-button mars-margin-right--sm"
            key={cta.url + index}>
            {cta.title}
          </Link>
        );
      })}
    </div>
  </ContentBlock>
);

const useCaseCategoryTitle = ({ category }) => {
  switch (category) {
    case `ecosystem`:
      return `How PPJ is helping activate the ecosystem`;
    case `brand-strategies`:
      return `How PPJ is helping develop division and brand strategies`;
    case `markets`:
      return `How PPJ is helping with brand activation in market`;
  }
};

const LandingPageTemplate = ({ data }) => {
  const useCaseBlocks = data.contentBlocks.filter(
    (block) => block.type === `paragraph__case_study_item`
  );
  const contentBlocks = data.contentBlocks.filter(
    (block) => block.type === `paragraph__text_with_image`
  );

  const useCaseBlocksGroups = useCaseBlocks.reduce((prev, current) => {
    prev[current.category] = prev[current.category] || [];
    prev[current.category].push(current);
    return prev;
  }, {});
  const useCaseBlockGroupKeys = Object.keys(useCaseBlocksGroups);

  const heroTypeTwo = data.hero;

  return (
    <>
      <ImageTextHero {...heroTypeTwo} />
      {contentBlocks.map((block, index) => (
        <div
          className="mars-margin-y--xl mars-padding-y--md mars-overflow-hidden"
          key={block.title + index}>
          {contentBlocksPart({ block })}
        </div>
      ))}
      {useCaseBlockGroupKeys.map((blockGroup, index) => (
        <div
          className="mars-container mars-margin-y--xl mars-padding-y--md"
          key={blockGroup + index}>
          <h2 className="mars-gamma">
            {useCaseCategoryTitle({ category: blockGroup })}
          </h2>
          {useCaseBlocksGroups[blockGroup].map((block) => (
            <UseCaseBlock block={block} />
          ))}
        </div>
      ))}
    </>
  );
};

export default LandingPageTemplate;
